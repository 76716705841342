import { useQuery, useMutation } from '@tanstack/vue-query';
import { type CartResponse, Response } from '~/components/cart/types';
import { validatorFactory } from '@laam/lib/validator';
const cartResponseValidator = validatorFactory<CartResponse>(Response);
import * as Sentry from '@sentry/vue';

// function to fetch cart and cart items using cartID
export const useGetCartById = (
	cartId: Ref<string>,
	customerEmail: Ref<string>,
	loganId: Ref<string>,
	accessToken: Ref<string>,
) => {
	const { data, isLoading, suspense, error, refetch } = useQuery<CartResponse>({
		queryKey: ['cartId', cartId],
		staleTime: Infinity,
		gcTime: Infinity,
		enabled: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const api = createApiInstance();
			const url = `cart_id=${cartId.value}&customer_email=${customerEmail.value}&logan_id=${loganId.value}&access_token=${accessToken.value}`;
			const response = await api.get(`/cart/get-cart?${url}`);

			if (response.status === 200) {
				const body = response.data;
				try {
					if (Object.keys(body.data.lines).includes('nodes'))
						body.data.lines = [];
					return cartResponseValidator.verify(body);
				} catch (err: any) {
					console.log(err);
					const error = new Error('Failed to validate get cart value response');
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err.message,
							lines: body.data.lines,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Failed to fetch cart');
			Sentry.captureException(error, (scope) => {
				scope.setContext('data', {
					response: response.data,
				});
				return scope;
			});
			throw error;
		},
	});
	return { data, isLoading, suspense, error, refetch };
};

// function to createCart
export const useCreateCart = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (queryParams: string) => {
			const api = createApiInstance();
			const response = await api.post(`/cart/create-cart?${queryParams}`);
			if (response.status === 200) {
				const body = response.data;
				try {
					if (Object.keys(body.data.lines).includes('nodes'))
						body.data.lines = [];
					return cartResponseValidator.verify(body);
				} catch (err) {
					console.log(err);
					const error = new Error('Failed to validate get cart value response');
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Failed to create cart');
			Sentry.captureException(error, (scope) => {
				scope.setContext('data', {
					response: response.data,
				});
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

// function to remove item
export const useRemoveCartItem = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			cart_id: string;
			line_ids: string | string[];
			customer_email: string;
			logan_id: string;
		}) => {
			const api = createApiInstance();
			const response = await api.post('/cart/remove-lineitem', body);
			if (response.status === 200) {
				const body = response.data;
				try {
					return body;
				} catch (err) {
					console.log(err);
					throw new Error('Validation error: ' + err);
				}
			}
			throw new Error('Invalid response from server');
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

interface Headers {
	[key: string]: string;
}

export const useUpdateCheckoutItem = (headers: Ref<Headers>) => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: object) => {
			const { params } = useRoute();
			const checkoutHash = params.chash;
			const api = createApiInstance();
			const response = await api.put(
				`/checkout/${checkoutHash}/lineitem`,
				body,
				{
					headers: headers!.value,
				},
			);
			if (response.status === 200) {
				const body = response.data;
				try {
					return body;
				} catch (err) {
					console.log(err);
					throw new Error('Validation error: ' + err);
				}
			}
			throw new Error('Invalid response from server');
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useUpdateCartItem = () => {
	const { data, isPending, isError, error, isSuccess, mutateAsync } =
		useMutation({
			mutationFn: async (body: {
				cart_id: string;
				lines: Object;
				customer_email: string;
				logan_id: string;
			}) => {
				const api = createApiInstance();
				const response = await api.post('/cart/update-lineitem', body);
				if (response.status === 200) {
					const body = response.data;
					try {
						return body;
					} catch (err) {
						console.log(err);
						throw new Error('Validation error: ' + err);
					}
				}
				throw new Error('Invalid response from server');
			},
		});
	return { isPending, isError, error, isSuccess, mutateAsync, data };
};

interface AddToCartDto {
	cart_id: string;
	lines: {
		attributes: {
			key: string;
			value: string;
		}[];
		merchandiseId: string;
		quantity: number;
	}[];
	customer_email: string;
	logan_id: string;
}

export const useAddToCart = () => {
	const { data, isPending, isError, error, isSuccess, mutateAsync } =
		useMutation({
			mutationFn: async (body: AddToCartDto) => {
				const api = createApiInstance();
				const response = await api.post('/cart/add-to-cart', body);
				if (response.status === 200) {
					const body = response.data;
					try {
						return body;
					} catch (err) {
						console.log(err);
						throw new Error('Validation error: ' + err);
					}
				}
				throw new Error('Invalid response from server');
			},
		});
	return { isPending, isError, error, isSuccess, mutateAsync, data };
};

export const useGetShippingChargesQuery = (
	countryCode: Ref<string>,
	cartValue: Ref<string>,
	cartShippingExperiment: Ref<boolean>,
) => {
	const enabled = computed(
		() => !!parseFloat(cartValue.value) && cartShippingExperiment.value,
	);
	const { data, isLoading, suspense, error, refetch } = useQuery({
		queryKey: ['countryCode', countryCode, 'cartValue', cartValue],
		staleTime: 300000,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: enabled,
		queryFn: async () => {
			const api = createApiInstance();
			const response = await api.post(
				`v1/checkout/shipping/v2?cart_value=${cartValue.value}&country_code=${countryCode.value}`,
				{},
				{
					headers: {
						country: countryCode.value,
					},
				},
			);

			if (response.status === 200) {
				try {
					return response.data;
				} catch (err: any) {
					const error = new Error(
						'Failed to validate get cart shipping charges response',
					);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err.message,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Failed to fetch cart shipping charges');
			Sentry.captureException(error, (scope) => {
				scope.setContext('data', {
					response: response.data,
				});
				return scope;
			});
			throw error;
		},
	});
	return { data, isLoading, suspense, error, refetch };
};
